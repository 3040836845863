import Connector from 'connector';

import { Card } from '@mui/material';

import './SingleInvoice.scss';
import { Formik, Form } from 'formik';
import SingleInvoiceFormContent from './SingleInvoiceFormContent';
import { singleInvoicePreSendSelector } from './SingleInvoiceSelector';

const SingleInvoice = ({ initialData, isNew, type }) => {
  const onSend = data => {
    const preparedPayload = singleInvoicePreSendSelector(data);
    Connector.postInvoice(preparedPayload);
  };

  return (
    <div className="SingleInvoice-page">
      <Card>
        <Formik initialValues={initialData} onSubmit={values => onSend(values)}>
          <Form>
            <SingleInvoiceFormContent type={type} isNew={isNew} />
          </Form>
        </Formik>
      </Card>
    </div>
  );
};

export default SingleInvoice;
