import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import FetchListPickerInput from 'modules/FetchListPickerInput';
import {
  TextInput,
  DateInput,
  SuccessButton,
  WarningButton,
  DangerButton,
} from 'components/CustomeMaterial';
import Connector from 'connector';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import useDelivery from 'hooks/useDelivery';
import useConfig from 'hooks/useConfig';
import { useLocation } from 'react-router-dom';

import './SingleDelivery.scss';
import AddSinglePoint from './AddSinglePoint/AddSinglePoint';
import SinglePointWrapper from './SinglePoint/SinglePointWrapper';

import connector from 'connector';

const SingleDelivery = () => {
  const navigate = useNavigate();
  const { PRODUCTS, UNITS } = useConfig();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [initialValues, setInitialValues] = useState({
    date: null,
    driverId: null,
    name: '',
    archive: 0,
    allowance: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  });

  const { points, setPoints, setAllowance } = useDelivery();
  const [localPoints, setLocalPoints] = useState([]);
  const [addSinglePointPopupState, setEditSinglePointPopupState] = useState({
    isOpen: false,
    data: null,
  });

  const filteredProducts = PRODUCTS.filter(item => !item.archive);

  const generateEmptyItemsConfig = locationIds => {
    return locationIds.split(',').map(locationId => ({
      locationId,
    }));
  };

  const getPreparedAllowance = allowance => {
    const preparedAllowance = {};

    if (Array.isArray(allowance)) {
      for (var i = 1; i <= 5; i++) {
        preparedAllowance[i] = allowance[i];
      }
    } else {
      return allowance;
    }

    return preparedAllowance;
  };

  const fetchExistingDelivery = async () => {
    const { data } = await Connector.getDeliveryById(id);
    setAllowance(JSON.parse(data.allowance));
    if (data.config !== null) {
      setPoints(JSON.parse(data.config));
    }
    setInitialValues({
      date: data.date,
      driverId: data.driverId,
      name: data.name,
      archive: data.archive,
      allowance: JSON.parse(data.allowance),
    });
  };

  const fetchFromTemplate = async () => {
    const { data } = await Connector.getDeliveryTemplateById(
      state.selectedDeliveryTemplate
    );

    const importedData = data?.data;

    setPoints(generateEmptyItemsConfig(importedData.locationsIds));
    setInitialValues({
      date: moment().format('YYYY-MM-DD'),
      driverId: importedData.driverId,
      name: importedData.name,
      archive: 0,
    });
  };

  useEffect(() => {
    setAllowance(initialValues.allowance);
    const selectedDeliveryTemplate = state?.selectedDeliveryTemplate;
    if (selectedDeliveryTemplate) {
      fetchFromTemplate();
    } else {
      if (id && id !== 'new') {
        fetchExistingDelivery();
      }
    }
  }, []);

  useEffect(() => {
    setLocalPoints(points);
  }, [points]);

  const onSubmit = async values => {
    const date = moment(values.date).format('YYYY-MM-DD');

    const allowance = getPreparedAllowance(values.allowance);

    const payload = await Connector.postDelivery({
      ...values,
      date,
      id: id === 'new' ? undefined : id,
      allowance: JSON.stringify(allowance),
      config: JSON.stringify(points),
    });

    if (id === 'new') {
      window.location.href = `/deliveries/${payload.data.id}`; // Native refresh with the new delivery ID
    }
  };

  const onSelect = values => {
    setPoints([...points, values]);
    setEditSinglePointPopupState({ isOpen: false });
  };

  if (
    (id && id !== 'new' && !initialValues.date) ||
    (state?.selectedDeliveryTemplate && !initialValues.date)
  ) {
    return <div>Loading Single Delivery...</div>;
  }

  return (
    <div className="SingleDelivery-page">
      <div className="card">
        <h4 className="page-title">Szczegóły trasy</h4>

        <FormikWrapper initialValues={initialValues} onSubmit={onSubmit}>
          <div className="general-form">
            <FetchListPickerInput name="driver" />
            <TextInput id="name" placeholder="Nazwa trasy" />
            <DateInput id="date" placeholder="Data" />
          </div>
          <h4>Zaplanowany naddatek</h4>
          <div className="allowance-form-container">
            {filteredProducts.map((product, index) => (
              <TextInput
                key={index}
                id={`allowance.[${product.id}]`}
                placeholder={`${product.name} (${UNITS[product.unitId]})`}
              />
            ))}
          </div>
          <div className="button-container">
            <SuccessButton type="submit">Zapisz</SuccessButton>
            <DangerButton
              onClick={() =>
                connector
                  .deleteDelivery(id)
                  .then(() => navigate('/deliveries/'))
              }
            >
              Usuń
            </DangerButton>
          </div>
        </FormikWrapper>
      </div>

      <div className="delivery-points card">
        {localPoints.map((point, index) => (
          <SinglePointWrapper
            index={index}
            key={index}
            locationId={point.locationId}
            isLast={index === localPoints.length - 1}
          />
        ))}

        <SuccessButton
          onClick={() => {
            setEditSinglePointPopupState({ isOpen: true });
          }}
        >
          Dodaj punkt
        </SuccessButton>
      </div>

      {addSinglePointPopupState.isOpen && (
        <AddSinglePoint
          onSelect={onSelect}
          onClose={() =>
            setEditSinglePointPopupState({
              isOpen: false,
              data: null,
            })
          }
        />
      )}
    </div>
  );
};

export default SingleDelivery;
