import { replaceDocumentIdentifier } from 'helpers/replaceDocumentIdentifier';
import moment from 'moment';
import { translator } from 'helpers/translator';

export const wasteCardsTableConfig = ({
  WASTE_DOCUMENT_NUMBER_FORMATS,
  UNITS,
  pagination,
  setPage,
  navigate,
}) => ({
  columns: [
    {
      label: 'Numer karty',
      id: 'number',
      onRender: row => {
        return (
          <span>
            {replaceDocumentIdentifier({
              date: row.transferDate,
              number: row.wasteNumber,
              format: WASTE_DOCUMENT_NUMBER_FORMATS[Number(row.driverId)],
            })}
          </span>
        );
      },
    },
    {
      label: 'Klient',
      id: 'client',
      onRender: row => {
        return <span>{row.client.companyName}</span>;
      },
    },
    {
      label: 'Lokalizacja',
      id: 'location',
      onRender: row => {
        return (
          <span>
            <b>{row.location.localName}</b>, {row.location.address},{' '}
            {row.location.city}
          </span>
        );
      },
    },
    {
      label: 'Data przekazania',
      id: 'transferDate',
      onRender: row => {
        return <span>{moment(row.transferDate).format('DD-MM-YYYY')}</span>;
      },
    },
    {
      label: 'Kierowca',
      id: 'driver',
      onRender: row => row.driver.login,
    },
    {
      label: 'Ilość',
      id: 'count',
      onRender: row => {
        return (
          <span>
            {row.count} {translator(UNITS, 5)}
          </span>
        );
      },
    },
    {
      label: 'Faktura',
      id: 'invoice',
      onRender: row => {
        if (row.noInvoice) {
          return <div className="no-invoice">Bez faktury</div>;
        }

        if (!row.noInvoice && !row.invoice) {
          return (
            <div className="wait-on-invoice">Oczekuje na zafakturowanie</div>
          );
        }

        const { createDate, number, numberFormat } = row.invoice;
        console.log(window.frontConfig);

        const documentIdentifier = row.invoice.isClientInvoice
          ? row.invoice.clientInvoiceNumber
          : replaceDocumentIdentifier({
              date: createDate,
              number: number,
              format: window.frontConfig.SELF_INVOICE_FORMATS[numberFormat],
            });

        return <div className="invoice-cell">{documentIdentifier}</div>;
      },
    },
  ],
  pagination: {
    ...pagination,
    setPage,
  },
  onClick: id => navigate(`/waste-cards/${id}`),
});
