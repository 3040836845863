import { replaceDocumentIdentifier } from 'helpers/replaceDocumentIdentifier';

export const prepareInvoiceNumber = ({
  isClientInvoice,
  clientInvoiceNumber,
  createDate,
  number,
  isSelfInvoice,
  numberFormat,
}) => {
  if (isClientInvoice) {
    return clientInvoiceNumber;
  }

  const { SELF_INVOICE_FORMATS, INVOICE_FORMATS } = window.frontConfig;

  return replaceDocumentIdentifier({
    date: createDate,
    number: number,
    format: isSelfInvoice
      ? SELF_INVOICE_FORMATS[numberFormat]
      : INVOICE_FORMATS[numberFormat],
  });
};
