import moment from 'moment';

export const replaceDocumentIdentifier = ({ format, date, number }) => {
  let documentIdentifier = format;

  const momentInstance = moment(date);

  documentIdentifier = documentIdentifier.replace(/NUM/g, number);
  documentIdentifier = documentIdentifier.replace(
    /MM/g,
    momentInstance.format('MM')
  );
  documentIdentifier = documentIdentifier.replace(
    /RRRR/g,
    momentInstance.format('YYYY')
  );

  return documentIdentifier;
};
