import { Grid } from '@mui/material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  TextInput,
  DateInput,
  FormWarning,
  Dropdown,
  SuccessButton,
  PrimaryButton,
  DangerButton,
  PaidCheckbox,
} from 'components/CustomeMaterial';

import FetchListPickerInput from 'modules/FetchListPickerInput';
import { prepareInvoiceNumber } from 'helpers/invoices.helpers';

import InvoiceItemsTable from './InvoiceItemsTable/InvoiceItemsTable';
import { useFormikContext } from 'formik';
import { map } from 'lodash';

import Connector from 'connector';

import useConfig from 'hooks/useConfig';
import { useEffect } from 'react';
import moment from 'moment';

const SingleInvoiceFormContent = ({ type, isNew }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { PAY_TYPES, INVOICE_CONFIG, INVOICE_FORMATS, SELF_INVOICE_FORMATS } =
    useConfig();
  const { setFieldValue, values } = useFormikContext();

  const fetchLastInvoiceNumber = async () => {
    const payload = await Connector.getLastInvoiceNumber({
      invoiceType: type,
      month: moment().month() + 1,
      year: moment().year(),
      format: values.numberFormat,
    });

    setFieldValue('number', Number(payload.data.data.number) + 1);
  };

  useEffect(() => {
    if (isNew && type !== INVOICE_CONFIG.CLIENT_INVOICE.name) {
      fetchLastInvoiceNumber();
    }
  }, [values.numberFormat]);

  const getSummaryNetto = () => {
    const sum = values.invoiceItems.reduce((acc, item) => {
      return acc + item.amount * item.nettoUnitPrice;
    }, 0);
    return sum.toFixed(2);
  };

  const getSummaryBrutto = () => {
    const sum = values.invoiceItems.reduce((acc, item) => {
      return (
        acc + item.amount * (item.nettoUnitPrice * (item.vatValue / 100 + 1))
      );
    }, 0);

    return sum.toFixed(2);
  };

  const getClientData = () => {
    return (
      <>
        <p>
          <b>Nazwa: </b>
          {values.client?.companyName}
        </p>
        <p>
          <b>NIP: </b>
          {values.client?.nip}
        </p>
        <p>
          <b>Klient: </b>
          {values.client?.clientName}
        </p>
        <p>
          <b>Miasto: </b>
          {values.client?.city}
        </p>
        <p>
          <b>Adres: </b>
          {values.client?.address}
        </p>
      </>
    );
  };

  const printPDF = async () => {
    const response = await Connector.getInvoicePDF(id);
    const pdfBlob = response.data;
    const url = window.URL.createObjectURL(
      new Blob([pdfBlob], { type: 'application/pdf' })
    );
    const pdfWindow = window.open();
    pdfWindow.location.href = url;
  };

  const getTitleInvoice = () =>
    `${isNew ? 'Nowa ' : ''} ${type} - ${prepareInvoiceNumber({ ...values })}`;
  const currentInvoiceFormat =
    type === 'Samofaktura' ? SELF_INVOICE_FORMATS : INVOICE_FORMATS;

  return (
    <>
      {type === INVOICE_CONFIG.CLIENT_INVOICE.name ? (
        <h3>{type}</h3>
      ) : (
        <div className="InvoiceTitle">
          <h2>{getTitleInvoice()}</h2>
        </div>
      )}
      <Grid container>
        <Grid item xs={12} md={3} className="client-data">
          <div className="client-description">
            <b>Dane klienta:</b>
            {getClientData()}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          {type === INVOICE_CONFIG.CLIENT_INVOICE.name ? (
            <TextInput id="clientInvoiceNumber" placeholder="Numer faktury" />
          ) : (
            <>
              <Dropdown
                id="numberFormat"
                label="Format numeru"
                options={map(currentInvoiceFormat, (item, key) => ({
                  value: key,
                  label: item,
                }))}
              />
              <TextInput id="number" placeholder="Numer faktury" />
            </>
          )}

          <FetchListPickerInput
            name="location"
            postFetchSelected={({ formikInstance, response }) => {
              formikInstance.setFieldValue('client', response?.clients);
              formikInstance.setFieldValue('clientId', response?.clients?.id);
              formikInstance.setFieldValue('location', response);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextInput id="placeOfCreate" placeholder="Miejsce wystawienia" />
          <DateInput id="createDate" placeholder="Data wystawienia" />
          <DateInput id="executionDate" placeholder="Data wykonania" />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            id="payType"
            label="Wybierz opcję"
            options={map(PAY_TYPES, (item, index) => ({
              value: index,
              label: item,
            }))}
          />
          <DateInput id="payTerm" placeholder="Termin płatności" />
          {values.payType == 2 ? (
            <PaidCheckbox id="paidDate" placeholder="Opłacona" />
          ) : (
            <>
              <DateInput id="paidDate" placeholder="Data opłacenia" />
              <FormWarning
                id="paidDate"
                message="Faktura nieopłacona"
                validation={value => {
                  return value === null;
                }}
              />
            </>
          )}
        </Grid>
      </Grid>

      <div className="invoice-items">
        <InvoiceItemsTable />

        <div className="bottom-bar">
          <div className="summary">
            <p>Wartość netto: {getSummaryNetto()} PLN</p>
            <p>Wartość brutto: {getSummaryBrutto()} PLN</p>
            <SuccessButton type="submit">Zapisz</SuccessButton>
            {!isNew && (
              <>
                <PrimaryButton onClick={printPDF}>
                  Drukuj / Generuj PDF
                </PrimaryButton>

                <DangerButton
                  onClick={async () => {
                    await Connector.deleteInvoice(id);
                    navigate('/invoices');
                  }}
                >
                  Usuń
                </DangerButton>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleInvoiceFormContent;
