import React, { useState, useEffect } from 'react';

import Connector from 'connector';
import { SuccessButton } from 'components/CustomeMaterial';
import FormikWrapper from 'components/FormikWrapper';
import { useNavigate, useParams } from 'react-router-dom';

import SingleWasteCardFormContent from './SingleWasteCardFormContent';

import './SingleWasteCard.scss';

const SingleWasteCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const isNew = id === 'new';
  const [wasteCard, setWasteCard] = useState(id !== 'new' ? null : {});

  const fetchData = async () => {
    const response = await Connector.getWasteCardById(id);

    if (response?.data) {
      setWasteCard(response.data);
    }
  };

  const onSubmitHandler = async values => {
    const wasteCartPayload = await Connector.postWasteCard({
      ...values,
      numberType: values.driverId,
    });
    const id = wasteCartPayload?.data?.data?.id;
    navigate(`/waste-cards/${id}`);
  };

  useEffect(() => {
    if (!isNew) {
      fetchData();
    }
  }, []);

  if (!wasteCard && !isNew) {
    return <div>Loading...</div>;
  }

  return (
    <div className="SingleWasteCard-page">
      {!isNew && (
        <div className="button-container">
          <SuccessButton
            onClick={() => {
              navigate(`/invoices/Samofaktura/new`, {
                state: {
                  selectedWasteCards: [Number(id)],
                },
              });
            }}
          >
            Stworz samofakturę
          </SuccessButton>
          <SuccessButton
            onClick={() => {
              navigate(`/invoices/Faktura_klienta/new`, {
                state: {
                  selectedWasteCards: [Number(id)],
                },
              });
            }}
          >
            Dodaj fakturę klienta
          </SuccessButton>
        </div>
      )}
      <div className="card">
        <h4 className="page-title">Karta odpadów</h4>
        <FormikWrapper initialValues={wasteCard} onSubmit={onSubmitHandler}>
          <SingleWasteCardFormContent isNew={isNew} />
        </FormikWrapper>
      </div>
    </div>
  );
};

export default SingleWasteCard;
