import { Button, Stack } from '@mui/material';
import moment from 'moment';
import { map } from 'lodash';

import { useNavigate, useParams } from 'react-router-dom';

import Notes from './Notes/Notes';
import AddEditLocal from 'modules/AddEditLocal/AddEditLocal';
import AdvancedTableWrapper from 'components/AdvancedTableWrapper/AdvancedTableWrapper';
import useConfig from 'hooks/useConfig';

import './SingleClient.scss';

import { useState } from 'react';

import Connector from 'connector';
import { useEffect } from 'react';

import Loading from 'modules/Loading/Loading';
import WasteCards from './WasteCards/WasteCards';
import FormikWrapper from 'components/FormikWrapper/FormikWrapper';
import {
  TextInput,
  CheckboxInput,
  SuccessButton,
  Dropdown,
  FormContextButton,
} from 'components/CustomeMaterial';
import Popup2 from 'modules/Popup2/Popup2';

const SingleClient = () => {
  const navigate = useNavigate();
  const { PAY_TYPES, INVOICE_PAY_TEMPS } = useConfig();
  const { id } = useParams();
  const [client, setClient] = useState();
  const [isCeidgSynchronizing, setIsCeidgSynchronizing] = useState(false);

  const fetchData = async () => {
    const response = await Connector.getClient(id);
    setClient(response?.data);
  };

  const onDeleteClient = async () => {
    await Connector.deleteClient(id);
    navigate(-1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onEditClient = async clientData => {
    await Connector.postClient({
      ...clientData,
      nip: String(clientData.nip),
      regon: String(clientData.regon) || undefined,
      isVat: clientData.isVat ? true : false,
      isSignedContract: clientData.isSignedContract ? true : false,
      archive: false,
    });
  };

  const getBasicClientData = data => {
    const { notes, locations, ...rest } = data;
    return rest;
  };

  const onCeidgUpdate = async ({ nip, regon }) => {
    const client = { nip, regon };
    setIsCeidgSynchronizing(true);
    const type = client?.nip ? 'nip' : 'regon';

    const response = await Connector.getClientDataByCeidg({
      type,
      value: client?.[type],
    });

    if (!response) {
      setIsCeidgSynchronizing(false);
      alert('Nie znaleziono firmy w CEIDG');
      return;
    }

    const clientData = {
      ...client,
      ...response?.data,
    };
    alert('Dane z CEIDG zostały zaktualizowane, pamiętaj o zapisaniu');

    setClient(clientData);
    setIsCeidgSynchronizing(false);
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className="SingleClient-page">
      <div className="SingleClient-wrapper">
        <h2>{client?.companyName}</h2>
        <div className="SingleClient-header">
          <Button onClick={onBack} color="warning">
            Powrót
          </Button>
          <Stack spacing={1} direction={'row'}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onDeleteClient()}
            >
              Usuń
            </Button>
          </Stack>
        </div>
        {client && !isCeidgSynchronizing ? (
          <div className="card">
            <FormikWrapper
              initialValues={getBasicClientData(client)}
              onSubmit={onEditClient}
            >
              <div className="button-container">
                <FormContextButton
                  onClick={context => {
                    return onCeidgUpdate({
                      nip: context.values.nip,
                      regon: context.values.regon,
                    });
                  }}
                >
                  Synchronizuj z CEIDG
                </FormContextButton>
              </div>
              <div className="form-container">
                <TextInput id="companyName" placeholder="Nazwa firmy" />
                <TextInput id="clientName" placeholder="Imię i nazwisko" />
                <TextInput id="address" placeholder="Adres" />
                <TextInput id="city" placeholder="Miasto" />
                <TextInput id="country" placeholder="Country" />
                <TextInput id="postalCode" placeholder="Kod pocztowy" />
                <TextInput id="phone" placeholder="Telefon" />
                <TextInput id="mobilePhone" placeholder="Telefon komórkowy" />
                <TextInput id="email" placeholder="Email" />
                <TextInput id="nip" placeholder="NIP" />
                <TextInput id="regon" placeholder="REGON" />
                <CheckboxInput id="isVat" placeholder="VATowiec" />
                <CheckboxInput id="isSignedContract" placeholder="Umowa" />
                <Dropdown
                  id="dirtyOilPayType"
                  placeholder="Typ płatności brudnego oleju"
                  options={map(PAY_TYPES, (value, key) => ({
                    value: key,
                    label: value,
                  }))}
                />
                <Dropdown
                  id="dirtyOilInvoiceType"
                  placeholder="Typ faktury brudnego oleju"
                  options={[
                    { value: 2, label: 'Samofaktura' },
                    { value: 3, label: 'Faktura_klienta' },
                  ]}
                />
                <Dropdown
                  id="clearOilPayType"
                  placeholder="Typ płatności czystego oleju"
                  options={map(PAY_TYPES, (value, key) => ({
                    value: key,
                    label: value,
                  }))}
                />
                <Dropdown
                  id="payTimeType"
                  placeholder="Okres terminu płatności"
                  options={map(INVOICE_PAY_TEMPS, (value, key) => ({
                    value: key,
                    label: value,
                  }))}
                />
              </div>
              <div className="form-buttons">
                <SuccessButton type="submit">Zapisz</SuccessButton>
              </div>
            </FormikWrapper>
          </div>
        ) : (
          <Loading />
        )}
        <div className="SingleClient-table">
          <AdvancedTableWrapper
            config={{
              columns: [
                { id: 'localName', label: 'Nazwa lokalu' },
                {
                  id: 'location',
                  label: 'Lokalizacja',
                  onRender: row =>
                    `${row.city}, ${row.postalCode}, ${row.address} `,
                },
                { id: 'phone', label: 'Telefon' },
                { id: 'country', label: 'Kraj' },
                { id: 'email', label: 'Email' },
                {
                  id: 'openingHours',
                  label: 'Godziny otwarcia',
                  onRender: row => {
                    return `${moment(row.openingTime, 'HH:mm:ss').format(
                      'HH:mm'
                    )} - ${moment(row.closingTime, 'HH:mm:ss').format(
                      'HH:mm'
                    )}`;
                  },
                },
                // {
                //   id: 'selfBilling',
                //   label: 'Samofakturowanie',
                //   onRender: row => (row.selfBilling ? 'Tak' : 'Nie'),
                // },
                {
                  id: 'payment',
                  label: 'Płatność',
                  onRender: row => PAY_TYPES[row.payment],
                },
                { id: 'additionalInfo', label: 'Dodatkowe informacje' },
                {
                  id: 'edit',
                  label: 'Edytuj',
                  onRender: row => (
                    <Popup2
                      openButtonText="Edytuj"
                      component={AddEditLocal}
                      componentProps={{
                        refetchData: fetchData,
                        id: row.id,
                        clientId: id,
                      }}
                      title="Edytuj lokal"
                    />
                  ),
                },
              ],
            }}
            data={client?.locations || []}
          />

          <div className="SingleClient-table-btn">
            <Popup2
              openButtonText="Dodaj lokal"
              title="Dodaj lokal"
              component={AddEditLocal}
              componentProps={{
                clientId: id,
                refetchData: fetchData,
              }}
            />
          </div>
          <div className="SingleClient-waste-cards">
            <WasteCards />
          </div>
          <div className="SingleClient-notes">
            <h3>Notatki</h3>
            <div className="SingleClient-notes-wrapper">
              <Notes
                notes={client?.notes || []}
                onRefreshClient={() => fetchData()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleClient;
