import {
  DateInput,
  WarningButton,
  SuccessButton,
} from 'components/CustomeMaterial';
import moment from 'moment';
import FormikWrapper from 'components/FormikWrapper';
import Connector from 'connector';

const CSVGenerator = ({ onClose, type, isSelfInvoice, isClientInvoice }) => {
  const connect =
    type === 'epp' ? Connector.getInvoicesEPP : Connector.getInvoicesCSV;
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');

  const onSubmit = async values => {
    const response = await connect({
      ...values,
      isSelfInvoice,
      isClientInvoice,
    });
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = type === 'epp' ? 'output.epp' : 'output.csv'; // Nazwa pliku CSV
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="CSV-EPP-Generator-module">
      <FormikWrapper initialValues={{ startDate, endDate }} onSubmit={onSubmit}>
        <>
          <div className="inputs-container">
            <DateInput id="startDate" placeholder="Data początkowa" />
            <DateInput id="endDate" placeholder="Data końcowa" />
          </div>
          <div className="button-container">
            <SuccessButton type="submit">Generuj</SuccessButton>
            <WarningButton type="button" onClick={onClose}>
              Anuluj
            </WarningButton>
          </div>
        </>
      </FormikWrapper>
    </div>
  );
};

export default CSVGenerator;
