import axios from 'axios';

class Connector {
  constructor() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_PATH,
    });

    instance.interceptors.request.use(this.handleRequest, this.handleError);
    instance.interceptors.response.use(this.handleSuccess, this.handleError);

    this.axiosInstance = instance;
  }

  createUrl = ({ route, params = {} }) => {
    if (params.sortBy && Array.isArray(params.sortBy)) {
      params.sortBy = params.sortBy.join(',');
    }
    if (params.order && Array.isArray(params.order)) {
      params.order = params.order.join(',');
    }

    const query = new URLSearchParams(params).toString();

    return query ? `${route}?${query}` : route;
  };

  handleRequest = config => {
    const token = localStorage.getItem('authToken');
    config.headers.authorization = token ? `Bearer ${token}` : '';
    return config;
  };

  handleSuccess = response => {
    document.showAlert(false, 'Sukces', 'Operacja zakończona sukcesem');
    return response;
  };

  handleError = error => {
    document.showAlert(true, 'Błąd', error.message);
  };
  // PARAMS EXAMPLE
  // const params = {
  //   limit: 10,
  //   page: 1,
  //   sortBy: ['city:asc', 'name:desc'],
  //   clientId: 123,
  //   city: 'Warsaw',
  // };

  //  ██████╗  ██████╗ ██╗   ██╗████████╗███████╗███████╗
  //  ██╔══██╗██╔═══██╗██║   ██║╚══██╔══╝██╔════╝██╔════╝
  //  ██████╔╝██║   ██║██║   ██║   ██║   █████╗  ███████╗
  //  ██╔══██╗██║   ██║██║   ██║   ██║   ██╔══╝  ╚════██║
  //  ██║  ██║╚██████╔╝╚██████╔╝   ██║   ███████╗███████║
  //  ╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝

  getConfig = () => {
    return this.axiosInstance.get('/front-config');
  };

  getClients = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/clients', params })
    );
  };

  getClientsByLocationName = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/clients/locationName/`, params })
    );
  };

  getClient = id => {
    return this.axiosInstance.get(this.createUrl({ route: `/clients/${id}` }));
  };

  postClient = data => {
    return this.axiosInstance.post('/clients', data);
  };

  deleteClient = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `clients/${id}` })
    );
  };

  getClientDataByCeidg = ({ type, value }) => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/clients/synchronize/${type}/${value}` })
    );
  };

  postNote = data => {
    return this.axiosInstance.post('/notes', data);
  };

  deleteNote = id => {
    return this.axiosInstance.delete(this.createUrl({ route: `notes/${id}` }));
  };

  getLocations = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/locations', params })
    );
  };

  getLocationsByIds = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/locations-by-ids`, params })
    );
  };

  postLocation = data => {
    return this.axiosInstance.post('/locations', data);
  };

  getLocation = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/locations/${id}` })
    );
  };

  deleteLocation = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `locations/${id}` })
    );
  };

  getDeliveries = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/delivery', params })
    );
  };

  postDelivery = data => {
    return this.axiosInstance.post('/delivery', data);
  };

  deleteDelivery = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `delivery/${id}` })
    );
  };

  getDeliveryById = id => {
    return this.axiosInstance.get(this.createUrl({ route: `/delivery/${id}` }));
  };

  getDeliveryTemplates = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/delivery-templates', params })
    );
  };

  getDeliveryTemplateById = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/delivery-templates/${id}` })
    );
  };

  getDrivers = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/drivers', params })
    );
  };

  getDriver = id => {
    return this.axiosInstance.get(this.createUrl({ route: `/drivers/${id}` }));
  };

  postDeliveryTemplate = data => {
    return this.axiosInstance.post('/delivery-templates', data);
  };

  deleteDeliveryTemplate = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `/delivery-templates/${id}` })
    );
  };

  // INVOICES

  getInvoices = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/invoices', params })
    );
  };

  getInvoice = id => {
    return this.axiosInstance.get(this.createUrl({ route: `/invoices/${id}` }));
  };

  getLastInvoiceNumber = ({ invoiceType, month, year, format }) => {
    return this.axiosInstance.get(
      this.createUrl({
        route: `/invoices-last-number/${invoiceType}/${month}/${year}/${format}`,
      })
    );
  };

  postInvoice = data => {
    return this.axiosInstance.post('/invoices', data);
  };

  deleteInvoice = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `/invoices/${id}` })
    );
  };

  getInvoicesCSV = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/invoices-csv', params }),
      {
        responseType: 'blob',
      }
    );
  };

  getInvoicesEPP = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/invoices-epp', params }),
      {
        responseType: 'blob',
      }
    );
  };

  getInvoicePDF = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/invoices-pdf/${id}` }),
      {
        responseType: 'blob',
      }
    );
  };

  getWasteCardsByClientIdAndDate = ({ id, date }) => {
    return this.axiosInstance.get(
      this.createUrl({
        route: `/waste-cards-by-client-and-month/${id}/month/${date}`,
      })
    );
  };

  getWasteCards = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/waste-cards', params })
    );
  };

  getWasteCardById = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/waste-cards/${id}` })
    );
  };

  getWasteCardsByIds = ids => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/waste-cards-by-ids/${ids}` })
    );
  };

  getWasteCardsNewNumber = ({ transferDate, driverId }) => {
    return this.axiosInstance.get(
      this.createUrl({
        route: `/waste-cards/new-number/${transferDate}/${driverId}`,
      })
    );
  };

  postWasteCard = data => {
    return this.axiosInstance.post('/waste-cards', data);
  };

  deleteWasteCard = id => {
    return this.axiosInstance.delete(`/waste-cards/${id}`);
  };

  postWasteCardsAsNoInvoice = ids => {
    return this.axiosInstance.post(
      this.createUrl({ route: `/waste-cards/no-invoice/${ids.join(',')}` })
    );
  };

  getWasteCardXLS = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/waste-cards-xls', params }),
      {
        responseType: 'blob',
      }
    );
  };

  getWasteCardSmallPDF = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/driver/waste-cards/${id}/pdf/small` }),
      {
        responseType: 'blob',
      }
    );
  };

  getWasteCardA4PDF = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/driver/waste-cards/${id}/pdf/a4` }),
      {
        responseType: 'blob',
      }
    );
  };

  getProducts = () => {
    return this.axiosInstance.get(this.createUrl({ route: '/products' }));
  };

  postProduct = data => {
    return this.axiosInstance.post('/products', data);
  };

  deleteProduct = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `/products/${id}` })
    );
  };

  // Warehouses
  getInventoryHistory = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/inventory-history', params })
    );
  };

  getInventoryHistoryById = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/inventory-history/${id}` })
    );
  };

  getProductHistory = ({ id, params }) => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/inventory-history/product/${id}`, params })
    );
  };

  postInventoryHistory = data => {
    return this.axiosInstance.post('/inventory-history', data);
  };

  putInventoryHistory = data => {
    return this.axiosInstance.put('/inventory-history', data);
  };

  deleteInventoryHistory = id => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `/inventory-history/${id}` })
    );
  };

  getSuppliers = () => {
    return this.axiosInstance.get(this.createUrl({ route: '/suppliers' }));
  };

  getSupplier = id => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/suppliers/${id}` })
    );
  };

  postSupplier = data => {
    return this.axiosInstance.post('/suppliers', data);
  };

  deleteSupplier = ({ id }) => {
    return this.axiosInstance.delete(
      this.createUrl({ route: `/suppliers/${id}` })
    );
  };
}

export default new Connector();
