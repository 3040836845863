import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import './CheckboxInput.scss';

const PaidCheckbox = ({ id, name, placeholder }) => {
  const formik = useFormikContext();
  const currentValue = !!formik.values[id];

  return (
    <div className="paid-checkbox checkbox-wrapper">
      <label className={`${currentValue ? 'true' : 'false'}`}>
        <Field name={name || id}>
          {({ field }) => (
            <input
              type="checkbox"
              id={id}
              {...field}
              checked={!!field.value}
              onChange={event => {
                const { checked } = event.target;
                console.log(
                  { checked },
                  checked ? null : moment().toISOString()
                );
                formik.setFieldValue(
                  field.name,
                  checked ? moment().toISOString() : null
                );
              }}
            />
          )}
        </Field>
        {placeholder}
      </label>
      {formik.errors[id] && (
        <span className="validation-error-description">
          {formik.errors[id]}
        </span>
      )}
    </div>
  );
};

export default PaidCheckbox;
