import Connector from 'connector';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import SingleInvoice from './SingleInvoice';
import useConfig from 'hooks/useConfig';
import {
  SingleInvoiceSelector,
  SingleInvoiceByWasteCardsSelector,
} from './SingleInvoiceSelector';
import { useParams } from 'react-router-dom';

const SingleInvoiceWrapper = () => {
  const { id, type } = useParams();
  const { INVOICE_CONFIG, INVOICE_PAY_TEMPS } = useConfig();
  const location = useLocation();
  const { state } = location;
  const isNew = id === 'new';

  const [initialData, setInitialData] = useState(null);

  const fetchData = async () => {
    let response = null;

    if (!isNew) {
      response = await Connector.getInvoice(id);
    }

    if (response?.data) {
      const selectedData = SingleInvoiceSelector(response.data);
      setInitialData(selectedData);
    }
  };

  const fetchLastInvoiceNumber = async () => {
    const month = moment().format('MM');
    const year = moment().format('YYYY');
    const payload = await Connector.getLastInvoiceNumber({
      invoiceType: type,
      month,
      year,
      format: 1,
    });
    const number = payload?.data?.data?.number || 0;
    const selectedData = SingleInvoiceSelector({
      isClientInvoice: type === INVOICE_CONFIG.CLIENT_INVOICE.name,
      isSelfInvoice: type === INVOICE_CONFIG.SELF_INVOICE.name,
      number: Number(number) + 1,
    });

    setInitialData(selectedData);
  };

  const fetchForWasteCards = async () => {
    console.log(state);
    let number = undefined;
    const wasteCardsIds = state.selectedWasteCards.join(',');
    const wasteCardsPayload = await Connector.getWasteCardsByIds(wasteCardsIds);

    const wasteCards = wasteCardsPayload?.data?.data;
    const isSelfInvoice = type === INVOICE_CONFIG.SELF_INVOICE.name;

    const selectedData = SingleInvoiceByWasteCardsSelector({
      wasteCards,
      isClientInvoice: type === INVOICE_CONFIG.CLIENT_INVOICE.name,
      isSelfInvoice,
      invoiceTermConstants: INVOICE_PAY_TEMPS,
    });

    if (isSelfInvoice) {
      const month = moment().format('MM');
      const year = moment().format('YYYY');
      const payload = await Connector.getLastInvoiceNumber({
        invoiceType: type,
        month,
        year,
        format: 1,
      });
      number = Number(payload?.data?.data?.number || 0) + 1;
    }
    setInitialData({
      ...selectedData,
      number,
    });
  };

  useEffect(() => {
    if (state !== null) {
      fetchForWasteCards();
    } else if (!isNew) {
      fetchData();
    } else {
      fetchLastInvoiceNumber();
    }
  }, []);

  if (initialData === null) {
    return <p>loading...</p>;
  } else {
    return (
      <SingleInvoice initialData={initialData} type={type} isNew={isNew} />
    );
  }
};

export default SingleInvoiceWrapper;
