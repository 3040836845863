export const getNettoValue = ({ amount, nettoUnitPrice }) => {
  if (!amount || !nettoUnitPrice) return 0;
  return (parseFloat(amount) * parseFloat(nettoUnitPrice)).toFixed(2);
};

export const getBruttoValue = ({ amount, nettoUnitPrice, vatValue }) => {
  if (!amount || !nettoUnitPrice || !vatValue) return 0;
  return (
    parseFloat(amount) *
    (parseFloat(nettoUnitPrice) * (Number(vatValue) / 100 + 1))
  ).toFixed(2);
};
