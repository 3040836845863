import { IMenuItems } from './Menu.types';
import { FaTable } from 'react-icons/fa';
import { FaTruck } from 'react-icons/fa';
import { GrStorage, GrTrash } from 'react-icons/gr';
import { FaRoad } from 'react-icons/fa';
import { LiaFileInvoiceSolid } from 'react-icons/lia';

export const menuItems: IMenuItems[] = [
  {
    value: 'Baza klientów',
    href: 'clients',
    icon: FaTable,
  },
  {
    value: 'Stany magazynowe',
    href: 'warehouse',
    icon: GrStorage,
  },
  {
    value: 'Trasy dostaw',
    href: 'deliveries',
    icon: FaRoad,
  },
  {
    value: 'Faktury',
    href: 'invoices',
    icon: LiaFileInvoiceSolid,
  },
  {
    value: 'Karty odpadów',
    href: 'waste-cards',
    icon: GrTrash,
  },
  {
    value: 'Generator KPO',
    href: 'wastecards-generator',
    icon: GrTrash,
  },
  {
    value: 'Dostawcy',
    href: 'suppliers',
    icon: FaTruck,
  },
];
